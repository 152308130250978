import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Jumping On The Clouds – HackerRank Challenge",
  "author": "tzookb",
  "type": "post",
  "date": "2019-05-28T16:02:03.000Z",
  "url": "/2019/05/jumping-on-the-clouds-hackerrank-challenge/",
  "medium_post": ["O:11:\"Medium_Post\":11:{s:16:\"author_image_url\";N;s:10:\"author_url\";N;s:11:\"byline_name\";N;s:12:\"byline_email\";N;s:10:\"cross_link\";N;s:2:\"id\";N;s:21:\"follower_notification\";N;s:7:\"license\";N;s:14:\"publication_id\";N;s:6:\"status\";N;s:3:\"url\";N;}"],
  "categories": ["Exercises"],
  "tags": ["golang", "HackerRank"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{` There are several way to solve this, but we want to have most efficient and easy to understand. That`}{`’`}{`s why I always mention to start your coding and problem solving on paper. Our code editors can wait`}{`…`}{` This is the exercise `}<a parentName="p" {...{
        "href": "https://www.hackerrank.com/challenges/jumping-on-the-clouds"
      }}>{`link`}</a>{` Check out the video I posted describing my solution:`}</p>
    <div className="wp-block-embed__wrapper">
  <iframe title="Jumping on the Clouds - HackerRank" width="800" height="600" src="https://www.youtube.com/embed/fowAst-zlRQ?feature=oembed" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div> and the code of course: 
    <pre><code parentName="pre" {...{}}>{`func jumpingOnClouds(c []int32) int32 {
    var jumps int32
    cidx := 0
    eidx := len(c) - 1
    for {
        if cidx == eidx {
            return jumps
        }
        twoStepIdx := cidx + 2
        cidx++
        if twoStepIdx &lt;= eidx &amp;&amp; c[twoStepIdx] == 0 {
            cidx++
        }
        jumps++
    }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      